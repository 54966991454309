<template>
  <!-- :action="$http.uploadUrl" -->
  <div class="upload-pics">
    <!-- accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG, .PNG, .GIF" -->
    <!-- accept="video/mp4" -->
    <el-upload accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG, .PNG, .GIF" list-type="picture-card" :file-list.sync="fileList"
      :limit="limit" :multiple="multiple" action="http://47.100.63.204:8421/manager/common/uploadFileXtg"
      :data="fileupload" :on-remove="handleRemove" :before-remove="beforeRemove" :before-upload="beforerUpload"
      :on-preview="handlePreview" :on-success="handleSuccess" :on-exceed="handExceed"
      :class="fileList.length==limit? 'hide_box' : ''">
      <i class="el-icon-plus" />
    </el-upload>
    <el-dialog :visible.sync="dialogVisible"><img width="100%" :src="dialogImageUrl" alt=""></el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    // 上传的文件列表
    fileList: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 最大允许上传个数
    limit: {
      type: Number,
      default: 1
    },
    
    // 是否支持多选文件
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      fileupload: {
        code: '1',
        businessId: 1,
        businessType: '1',
      },
    }
  },
  // created(){
  //   console.log(this.fileList)
  // },
  computed: {
    list: {
      get() {
        return this.fileList
      },
      set(val) {
        this.$emit('update:fileList', val)
        console.log(this.fileList.length+'------'+this.limit)
      }
    }
  },
  methods: {
    // 上传文件之前的钩子
    beforerUpload(res) {
      console.log(res)
     },
    // 文件上传成功后
    handleSuccess(res, file, fileList) {
      // 给文件列表添加id
      // console.log(res)
      // console.log(fileList)
      this.fileList=fileList
      this.$emit('fileList', res.data)
      // console.log(this.fileList)
    },
    // 删除文件之前的钩子
    beforeRemove(file, fileList) {
      // console.log(fileList)
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      // console.log(file)
      this.fileList=[]
      this.$emit('moveList', file)
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
      console.log(this.dialogImageUrl)
    },
    // 文件超出个数限制时的钩子
    handExceed() {
      this.$message.warning('已超出上传数量')
    }
  }
}
</script>

<style lang="scss" scoped>
$size: 100px;

.upload-pics {
  /deep/ .el-upload-list__item {
    width: $size;
    height: $size;
  }

  /deep/ .el-upload--picture-card {
    width: $size;
    height: $size;
    line-height: $size;
  }

  /deep/ .el-progress-circle {
    width: 100%;
    height: 100%;
    transform: scale(0.6);
  }



}

.hide_box {
  ::v-deep.el-upload--picture-card {
    display: none;
  }
}
</style>



