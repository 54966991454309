<template>
  <section class="gardenManagementPage">
    <SearchForm class="dis-flex flex-x-start flex-y-start">
      <el-button type="success" @click="add" class="turnBule">创建活动</el-button>
    </SearchForm>

    <TableContainer title="">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        border
        :header-cell-style="tabHeader"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column prop="id" label="ID" min-width="30" />
        <el-table-column prop="activityName" label="活动名称" min-width="60" />
        <el-table-column prop="wxCode" label="企业微信活码" min-width="60">
          <template slot-scope="scope">
            <div>
              <img :src="scope.row.wxCode" width="60" height="60" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="beginDay" label="开始时间" min-width="60" />
        <el-table-column prop="endDay" label="结束时间" min-width="60" />
        <el-table-column prop="createDay" label="提交时间" min-width="60" />
        <el-table-column prop="activityNums" label="可参与人数" min-width="40">
          <template slot-scope="scope">
            <div>
              {{ scope.row.activityNums == "0" ? "不限制" : scope.row.activityNums }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userNums" label="实际参与人数" min-width="60" />
        <el-table-column width="300" label="操作">
          <template slot-scope="scope">
            <el-button type="text" class="textOrange" @click="searchClick(scope.row)"
              >查询</el-button
            >
            <el-button type="text" class="textOrange" @click="editorClick(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" class="textOrange" @click="delteClick(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <GPagination
        v-show="total > 0"
        ref="ref_GPagination"
        :total="total"
        :page.sync="searchForm.page"
        :size.sync="searchForm.size"
        @pagination="querycshopmallKocActivityPage"
        :page-sizes="pageSizes"
      />
    </TableContainer>

    <!-- 添加活动s -->
    <GDialog
      :dialog.sync="themeDialog"
      @btnSure="submitThemeForm()"
      @btnClose="resetThemeForm()"
      width="80%"
    >
      <el-form ref="ref_themeForm" label-width="100px" @submit.native.prevent>
        <!-- 活动类型 -->
        <div class="dis-flex flex-x-start flex-y-center">
          <el-form-item label="活动类型" label-width="100px">
            <div style="width: 220px">
              <el-select
                v-model="parmas.type"
                placeholder="单位"
                @change="changeUnit"
                class="t-c"
              >
                <el-option
                  class="t-c"
                  v-for="(item, index) in unitList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="活动名称" label-width="100px">
            <div style="width: 220px">
              <el-input
                v-model.trim="parmas.activityName"
                placeholder="请输入活动名称"
                type="text"
              />
            </div>
          </el-form-item>

          <el-form-item label="活动时间" label-width="100px">
            <el-date-picker
              v-model="dayTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="yyyy-MM-dd HH:mm:ss"
              data-format="yyyy-MM-dd HH:mm:ss"
              @change="selectTime"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="参与人数" label-width="100px">
            <div style="width: 220px">
              <el-input
                v-model.trim="parmas.activityNums"
                placeholder="0则默认不限制参与人数"
                type="text"
              />
            </div>
          </el-form-item>
        </div>

        <div class="dis-flex flex-x-start flex-y-start">
          <div class="dis-flex flex-x-start flex-y-start">
            <el-form-item label="活动海报(700*400)" label-width="180px">
              <el-upload
                action="http://47.100.63.204:8421/manager/common/uploadFileXtg"
                list-type="picture-card"
                :on-preview="bannerhandlePictureCardPreview"
                :on-remove="bannerhandleRemove"
                :on-success="bannerhandleSuccess"
                :file-list.sync="bannerfileList"
                :class="parmas.banner !== '' ? 'hide_box' : ''"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="bannerdialogVisible">
                <img width="100%" :src="bannerdialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
            <el-form-item label="企业微信活码(300*300)" label-width="180px">
              <el-upload
                action="http://47.100.63.204:8421/manager/common/uploadFileXtg"
                list-type="picture-card"
                :on-preview="wxhandlePictureCardPreview"
                :on-remove="wxhandleRemove"
                :on-success="wxhandleSuccess"
                :file-list.sync="wxfileList"
                :class="parmas.wxCode !== '' ? 'hide_box' : ''"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="wxdialogVisible">
                <img width="100%" :src="wxdialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </div>
        </div>

        <el-form-item label="活动规则" label-width="100px">
          <div>
            <Tinymce :height="600" v-model="tiny.value"></Tinymce>
          </div>
        </el-form-item>
      </el-form>
    </GDialog>
    <!-- 添加活动e -->

    <!-- 查询活动s -->
    <GDialog
      :dialog.sync="searchDialog"
      @btnClose="resetThemeForm()"
      width="90%"
      :showFooter="false"
    >
      <!-- <TableContainer title=""> -->
      <el-table
        :data="detailData"
        stripe
        style="width: 100%"
        border
        :header-cell-style="tabHeader"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column prop="id" label="ID" min-width="30" />
        <el-table-column prop="avatarUrl" label="头像" min-width="60">
          <template slot-scope="scope">
            <div>
              <img :src="scope.row.avatarUrl" width="60" height="60" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="nickName" label="微信相关信息" min-width="80" >
          <template slot-scope="scope">
            <div class="t-l">
              <div class="m-top4">
                <p>微信号</p>
              <p class="f-w">{{scope.row.wechatId}}</p>
              </div>
              <div class="m-top4">
                <p>微信昵称</p>
              <p class="f-w">{{scope.row.nickName}}</p>
              </div>
         
             
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userintegral" label="用户积分" min-width="50" />
        <el-table-column prop="redBookName" label="小红书/抖音/快手昵称" min-width="60" />
        <el-table-column prop="redBookId" label="小红书/抖音/快手ID号" min-width="60" />
        <el-table-column prop="noteLink" label="笔记链接" min-width="120" />


        <el-table-column prop="noteCollectionImage" label="笔记图" min-width="60">
          <template slot-scope="scope" >
          <span v-for="(item2,index2) in scope.row.noteCollectionImage" >
            <el-popover
              placement="left"
              width="400"
              trigger="click">
               <img  :src="item2" width="300px" height="400px" >
            <img  slot="reference" :src="item2" width="60px" height="60px" >
          </el-popover>

          </span>
        </template>

     
        </el-table-column>
        <el-table-column label="审核状态" min-width="120">
          <template slot-scope="scope">
            <p>{{scope.row.activityName}}</p>
            <div class="t-c m0 " style='width:80%;line-height:40px;'>
             <span :class="scope.row.isExamine==1?'':'col-red'"> {{ scope.row.examineName}}</span>  <span v-show="scope.row.isExamine==1">
              +{{ scope.row.integral}}分
            </span>
            </div>
           
            <div v-show="(scope.row.isExamine==1&&scope.row.remarks!==null)||(scope.row.isExamine==2&&scope.row.remarks!==null)">
             {{scope.row.isExamine==2?'驳回':scope.row.isExamine==1?'加分':''}}原因:{{ scope.row.remarks}}
            </div>
           
           
          </template>
        </el-table-column>
        <el-table-column prop="createDay" label="提交时间" min-width="100" />
        <el-table-column width="150" label="操作">
          <template slot-scope="scope">
            <el-button type="text" class="textOrange" @click="approveClick(scope.row)"
            v-show="scope.row.isExamine==0">审核</el-button
            >
            <el-button type="text" class="textOrange" @click="unapproveClick(scope.row)"
            v-show="scope.row.isExamine==0">驳回</el-button
            >
            <el-button type="text" class="textOrange" @click="failedClick(scope.row)"
            v-show="scope.row.isExamine==1">取消审核</el-button
            >
            <el-button type="text" class="textOrange" @click="delteClicks(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <GPagination
        v-show="detailTotal > 0"
        ref="ref_GPagination"
        :total="detailTotal"
        :page.sync="detailForm.page"
        :size.sync="detailForm.size"
        @pagination="querycshopmallKocNotesPage"
        :page-sizes="pageSizes"
      />
      <!-- </TableContainer> -->
    </GDialog>
    <!-- 查询活动e -->
        <!-- 审核s -->
        <GDialog
      :dialog.sync="approveDialog"
      @btnSure="approvesubmitThemeForm()"
      @btnClose="resetThemeForm()"
      width="30%"
    >
      <el-form ref="ref_themeForm" label-width="100px" @submit.native.prevent>
        <el-form-item label="活动名称" label-width="100px">

<div style="width: 220px">
  <p>{{approveRow.activityName}}</p>
</div>

</el-form-item>
        <el-form-item label="用户昵称" label-width="100px">

<div style="width: 220px">
  <p>{{approveRow.nickName}}</p>
</div>

</el-form-item>
        <el-form-item label="积分数值" label-width="100px">
          <div style="width: 220px">
            <el-input
              v-model.trim="integral"
              placeholder="请输入积分数值"
              type="number"
            />
          </div>
        </el-form-item>
        <el-form-item label="备注" label-width="100px">
          <div >
            <el-input  v-model.trim="remarks" type="textarea" maxlength="100" placeholder="请输入备注" />
          </div>
        </el-form-item>
  
      </el-form>
    </GDialog>
    <!-- 审核e -->
  </section>
  </section>
</template>

<script>
import { parseTime } from "@utils/filters";
import Tinymce from "./tinymce";
import {
  querycshopmallKocActivityPage,
  querycshopmallKocNotesPage,
  addcshopmallKocActivity,
  updatecshopmallKocActivity,
  updatecshopmallKocNotes,
  delcshopmallKocNotes,
  deljfProduct,
  addjfProduct,
  queryjfProductclassPage,
  productintegralSum,
  // querycshopmallKocActivity
} from "@api/bx/api_contentManagement";
import uploadPics from "@/components/Upload/uploadPics.vue";
import uploadPicss from "@/components/Upload/uploadPicss.vue";

export default {
  components: {
    uploadPics,
    uploadPicss,
    Tinymce,
  },
  data() {
    return {
      approveDialog: {
        title: "审核",
        visible: false,
      },
      themeDialog: {
        title: "添加",
        visible: false,
      },
      searchDialog: {
        title: "活动",
        visible: false,
      },
      tiny: {
        value: undefined,
      },
      searchForm: {
        page: 1,
        size: 10,
      },
      total: 0,
      tableData: [],
      detailForm: {
        page: 1,
        size: 10,
        activityId: 0,
      },
      detailData: [],
      detailRow: {},
      detailTotal: 0,
      pageSizes: [10, 20, 30, 40, 50],
      people: "",
      typeList: [],
      unitList: [
        { name: "抖音", id: "0" },
        { name: "小红书", id: "1" },
        { name: "快手", id: "2" },
      ],
      statusList: [
        { name: "上架", id: "0" },
        { name: "下架", id: "1" },
      ],
      dayTime: "",
      editor: false,
      index: 0,
      parmas: {
        activityName: "",
        activityNums: 0,
        banner: "",
        beginDay: "",
        type: "0",
        endDay: "",
        rules: "",
        wxCode: "",
      },
      currentTime: "",
      // dayTime: "",
      bannerfileList: [],
      bannerdialogImageUrl: "",
      bannerdialogVisible: false,
      wxfileList: [],
      wxdialogImageUrl: "",
      wxdialogVisible: false,
      approveRow:{},
      integral:'',
      remarks:''
    };
  },
  created() {
    // 3333
    this.currentTime = parseTime(new Date(), "{y}-{m}-{d} {h}:{i}:{s}");
    console.log(this.currentTime);
    this.querycshopmallKocActivityPage();
  },
  methods: {
    // 文件上传成功后
    bannerhandleSuccess(res) {
      this.parmas.banner = res.data.rootPath;
    },
    bannerhandleRemove(file, fileList) {
      this.parmas.banner = "";
    },
    bannerhandlePictureCardPreview(file) {
      this.bannerdialogImageUrl = file.url;
      this.bannerdialogVisible = true;
    },
    wxhandleSuccess(res) {
      this.parmas.wxCode = res.data.rootPath;
    },
    wxhandleRemove(file, fileList) {
      this.parmas.wxCode = "";
    },
    wxhandlePictureCardPreview(file) {
      this.wxdialogImageUrl = file.url;
      this.wxdialogVisible = true;
    },

    tabHeader() {
      return "backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;";
    },
    // selectTime(res) {
    //   console.log(res)
    //   this.dayTime = [parseTime(res[0], "{y}-{m}-{d}"), parseTime(res[1], "{y}-{m}-{d}")];
    //   // console.log(this.dayTime[0])
    //   // console.log(this.dayTime[1])
    //   // this.searchForm.beginTime=parseTime(res[0],'{y}-{m}-{d}')
    //   // this.searchForm.endTime=parseTime(res[1],'{y}-{m}-{d}')
    // },
    clearData() {
      this.parmas.activityName = "";
      this.parmas.activityNums = 0;
      this.parmas.banner = "";
      this.parmas.beginDay = "";
      this.parmas.type = "0";
      this.parmas.endDay = "";
      this.parmas.rules = "";
      this.parmas.wxCode = "";
      this.dayTime = "";
      this.bannerfileList = [];
      this.bannerdialogImageUrl = "";
      this.bannerdialogVisible = false;
      this.wxfileList = [];
      this.wxdialogImageUrl = "";
      this.wxdialogVisible = false;
    },
    //列表时间筛选
    selectTime(val) {
      console.log(val);
      if (val == null) {
        this.dayTime = "";
        this.parmas.beginDay = "";
        this.parmas.endDay = "";
      } else {
        this.dayTime = [
          parseTime(val[0], "{y}-{m}-{d} {h}:{i}:{s}"),
          parseTime(val[1], "{y}-{m}-{d} {h}:{i}:{s}"),
        ];
        this.parmas.beginDay = this.dayTime[0];
        this.parmas.endDay = this.dayTime[1];
        // console.log( this.parmas.beginDay+'---'+this.parmas.endDay)
      }
    },

    // 分类列表
    queryjfProductclassPage() {
      queryjfProductclassPage({
        page: 1,
        size: 10,
        classname: "",
      }).then((res) => {
        this.typeList = res.records;
        this.parmas.productid = res.records[0].id;
        this.index = 0;
      });
    },

    // 列表
    querycshopmallKocActivityPage() {
      querycshopmallKocActivityPage(this.searchForm).then((res) => {
        this.tableData = res.records;
        this.total = res.total;
      });
    },

    changeType(val) {
      this.parmas.productid = val;
      this.typeList.map((item, index) => {
        if (this.parmas.productid == item.id) {
          this.index = index;
        }
      });
    },
    changeUnit(val) {
      this.parmas.productunit = val;
    },
    changeUp(val) {
      this.parmas.upanddown = val;
    },
    inputPrice() {
      // this.parmas.productintegral =this.parmas.productprice * this.typeList[this.index].coefficient;
      if (this.parmas.productprice !== "") {
        productintegralSum({
          productid: this.parmas.productid,
          productprice: this.parmas.productprice,
        }).then((res) => {
          this.parmas.productintegral = res.productintegral;
        });
      } else {
        this.parmas.productintegral = "";
        
      }
    },
    //添加
    add() {
      this.editor = false;
      this.clearData();
      this.themeDialog.visible = true;
    },
    addcshopmallKocActivity() {
      addcshopmallKocActivity({
        productid: this.parmas.productid,
        productprice: this.parmas.productprice,
      }).then((res) => {
        this.parmas.productintegral = res.productintegral;
      });
    },
    //确定
    submitThemeForm() {
      this.parmas.rules = this.tiny.value;
      // console.log(this.parmas);
      if (this.parmas.activityName == "") {
        this.$alert("请输入活动名称", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.dayTime == "") {
        this.$alert("请输入活动时间", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.parmas.banner == "") {
        this.$alert("请上传活动海报", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
        
      }
      if (this.parmas.wxCode == "") {
        this.$alert("请上传企业微信活码", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.editor) {
        updatecshopmallKocActivity(this.parmas).then((res) => {
          this.themeDialog.visible = false;
          this.clearData();
          this.querycshopmallKocActivityPage();
        });
      } else {
        addcshopmallKocActivity(this.parmas).then((res) => {
          this.themeDialog.visible = false;
          this.querycshopmallKocActivityPage();
        });
      }
    },
    //取消按钮
    resetThemeForm() {
      // this.clearData()
      this.themeDialog.visible = false;
      this.querycshopmallKocActivityPage();
    },
    //查询
    searchClick(row) {
      this.detailRow = row;
      this.querycshopmallKocNotesPage();
    },
    querycshopmallKocNotesPage() {
      this.searchDialog.title = this.detailRow.activityName;
      this.detailForm.activityId = this.detailRow.id;
      querycshopmallKocNotesPage(this.detailForm).then((res) => {
        res.records.map((item, index) => {
          if(item.noteCollectionImage!==''||item.noteCollectionImage!==null){
         item.noteCollectionImage=item.noteCollectionImage.split(',')


          }
      });
        this.detailData = res.records;
        this.detailTotal = res.total;
        this.searchDialog.visible = true;
      });
    },

    //编辑
    editorClick(row) {
      this.clearData();
      // querycshopmallKocActivity({
      //       id: row.id,
      //     }).then((res) => {
      //       console.log(res)
      //     });
      this.editor = true;
      this.parmas = row;
      this.bannerfileList = [
        {
          name: "",
          url: row.banner,
        },
      ];
      this.wxfileList = [
        {
          name: "",
          url: row.wxCode,
        },
      ];
      this.tiny.value = row.rules;
      this.dayTime = [row.beginDay, row.endDay];
      this.themeDialog.visible = true;
    },

    //通过审核
    approveClick(row) {
      this.approveRow=row
      this.approveDialog.visible=true
    },
    
    approvesubmitThemeForm(){
      if(this.integral==''){
        this.$alert("请输入积分数值", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if(this.remarks==''){
        this.$alert("请输入备注", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
                updatecshopmallKocNotes({
            id: this.approveRow.id,
            isExamine: 1,
            integral: this.integral,
            remarks:this.remarks
          }).then((res) => {
            this.approveDialog.visible=false
            this.querycshopmallKocNotesPage();
          });
    },
    unapproveClick(row){
      this.$prompt("驳回"+ row.redBookName + "这条数据,请填写驳回原因！", "驳回", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        // inputValidator: (val) => { if(!val)return '驳回原因必填'},
        inputPattern: /^.+$/,
        inputErrorMessage: "请填写驳回原因！",
      }).then(({ value }) => {
        updatecshopmallKocNotes({
            id: row.id,
            isExamine: 2,
            remarks:value

          }).then((res) => {
            this.querycshopmallKocNotesPage();
          });
        })
        .catch((value) => {
          console.log("关闭" + value);
        });
  
    },

    delteClicks(row){
      this.$confirm("删除" + row.redBookName + "这条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delcshopmallKocNotes({
            id: row.id,
          }).then((res) => {
            this.querycshopmallKocNotesPage();
          });
      });
  
    },

    //取消审核
    failedClick(row) {
      this.$confirm(
        "扣除上次添加的" + row.integral + "积分, 是否继续取消审核?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        updatecshopmallKocNotes({
            id: row.id,
            isExamine: 3,
            integral: row.integral,
          }).then((res) => {
            this.querycshopmallKocNotesPage();
          });
      });
    },

    //删除
    delteClick(row) {
      this.$confirm("永久删除" + row.activityName + "这条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        updatecshopmallKocActivity({ id: row.id, isDelete: 1 }).then((res) => {
          this.querycshopmallKocActivityPage();
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.red {
  color: red;
}
.turnBule {
  color: #333;
  background-color: #58e3e8;
  border-color: rgba(56, 185, 190, 0.12);
  margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
  background: rgba(56, 185, 190, 0.5);
  border-color: rgba(56, 185, 190, 0.5);
  color: #fff;
}

.el-button--text.reviewed {
  color: #38b9be;
}

.reviewed {
  color: #38b9be;
}

.el-tree-node__content {
  z-index: 999;
  padding-left: 40px;
}

.directory-in {
  width: 32%;
  line-height: 60px;
  background: #4095e5;
}
.hide_box {
  ::v-deep.el-upload--picture-card {
    display: none;
  }
}
</style>

<style>
.tox-tinymce-aux {
  z-index: 3333333;
}
</style>

